import * as React from "react";

import { PageMeta, SecondaryPageTitle, SeriousTalk } from "../components/shared/secondary-page-elements";

import DownloadModal from "../components/shared/download-modal";
import Footer from "../components/sections/footer";
import Grid from "../components/shared/grid";
import { H5 } from "../components/shared/headings";
import Layout from "../components/shared/layout";
import { Link } from "gatsby";
import Navbar from "../components/navbar";
import Section from "../components/shared/section";
import Seo from "../components/shared/seo";

const TOSPage = () => {
  return (
    <Layout>
        <Seo 
            title={"Terms of Service | Aug 6, 2019 | Copilot"}
        />
        <DownloadModal/>
        <Navbar/>
        <Section>
            <Grid>
                <SecondaryPageTitle>Terms of service</SecondaryPageTitle>
                <PageMeta>
                    <H5>August 6, 2019</H5>
                    <p><Link to="/terms-of-service">See current version</Link></p>
                    <p><Link to="/terms-of-service-2021-09-15">See version from September 15, 2021</Link></p>
                    <p><Link to="/terms-of-service-2019-12-20">See version from December 20, 2019</Link></p>
                </PageMeta>
                <SeriousTalk>
                    <p>Welcome, and thank you for your interest in Copilot Money,
                    Inc. (“<b>Copilot</b>,” “<b>we</b>,” “<b>our</b>,” or “<b>us</b>”). These terms
                    of service (“<b>Terms</b>”) establish the terms and conditions that apply to
                    you when you use the Service (as defined below).</p>

                    <p>BY USING THE SERVICE OR BY CLICKING “I AGREE” YOU INDICATE
                    YOUR ACCEPTANCE OF THESE TERMS AND YOUR AGREEMENT TO BE BOUND BY THESE TERMS,
                    AS WELL AS ALL APPLICABLE LAWS AND REGULATIONS. YOU ARE NOT PERMITTED TO USE
                    THE SERVICE IF YOU DO NOT AGREE TO THESE TERMS. THESE TERMS CAN BE CHANGED,
                    MODIFIED, SUPPLEMENTED, AND/OR UPDATED BY COPILOT AT ANY TIME. WE WILL TAKE
                    REASONABLE EFFORTS TO PROVIDE YOU WITH PRIOR NOTICE OF ANY MATERIAL CHANGES TO
                    THESE TERMS. YOUR CONTINUED USE OF THE SERVICE AFTER THE MODIFICATION OF THESE TERMS
                    MEANS THAT YOU ACCEPT ALL SUCH CHANGES. YOU SHOULD CONSULT THESE TERMS EACH
                    TIME YOU ACCESS THE SERVICE TO VIEW ANY CHANGES. THESE TERMS WERE LAST MODIFIED
                    AS OF THE DATE INDICATED ABOVE.</p>

                    <p><b>AS DESCRIBED BELOW, THESE TERMS PROVIDE FOR THE USE OF
                    ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES, RATHER THAN JURY TRIALS
                    OR CLASS ACTIONS, AND LIMIT THE REMEDIES AVAILABLE TO YOU IN THE EVENT OF A
                    DISPUTE. YOU CAN OPT-OUT OF THIS AGREEMENT TO ARBITRATE BY CONTACTING SUPPORT@COPILOT.MONEY
                    WITHIN 30 DAYS AFTER FIRST ACCEPTING THESE TERMS AND STATING YOUR FIRST AND
                    LAST NAME AND THAT YOU DECLINE THIS AGREEMENT TO ARBITRATE.</b></p>

                    <p>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Use of the Service.</b></p>

                    <p>A.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Service</i></b>. The
                    “<b>Service</b>” includes Copilot’s website located at https://www.copilot.money
                    (“<b>Site</b>”), and Copilot’s related mobile applications (the “<b>Apps</b>”) as
                    each may be updated, relocated, or otherwise modified from time to time, all
                    content and services Copilot makes available through the Site or Apps, and all
                    intellectual property contained therein. The Service is a personal finance
                    information management tool that allows you to track and organize your finances,
                    including through the aggregation of your financial account information (“<b>Account
                    Data</b>”) from financial institutions and other third-party data sources you
                    select and with whom you have a contractual relationship (“<b>Third-Party Data
                    Sources</b>”). Any person who accesses and/or uses the Service, whether on his
                    or her own behalf or on behalf of any third party, will be referred to herein as
                    a “<b>Copilot User</b>.”</p>

                    <p>B.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Service
                    Restrictions</i></b>. Your Copilot account is personal to you. You may not use the
                    Service for commercial purposes. To use the Service, you must have access to
                    the Internet.</p>

                    <p>C.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>License</i></b>. Subject to your
                    compliance with these Terms, Copilot hereby grants you a limited, revocable,
                    non-exclusive, non-transferable license to download the Apps and access and use
                    the Service, solely for your personal use and not for resale. </p>

                    <p>D.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Authorization</i></b>. By accessing and
                    using the Service, you authorize and direct Copilot, as your agent and on your
                    behalf, to electronically retrieve your Account Data from Third-Party Data
                    Sources. Subject to our <Link to="/privacy-policy">Privacy Policy</Link>, Copilot may work with one or more
                    third-party financial service technology providers to access and retrieve your
                    Account Data. FOR PURPOSES OF THIS AGREEMENT AND SOLELY TO OBTAIN AND PROVIDE
                    THE ACCOUNT DATA TO YOU AS PART OF THE SERVICE, YOU GRANT COPILOT A LIMITED
                    POWER OF ATTORNEY, AND APPOINT COPILOT AS YOUR ATTORNEY-IN-FACT AND AGENT, WITH
                    FULL POWER OF SUBSTITUTION AND RE-SUBSTITUTION, FOR YOU AND IN YOUR NAME, PLACE
                    AND STEAD, IN ALL CAPACITIES, TO ACCESS SITES, SERVERS, OR DOCUMENTS RELATED TO
                    OR MAINTAINED BY THE THIRD-PARTY DATA SOURCES, RETRIEVE INFORMATION, AND USE
                    YOUR INFORMATION WITH THE FULL POWER AND AUTHORITY TO PERFORM EVERY ACT AND
                    THING REQUISITE AND NECESSARY TO BE DONE IN CONNECTION WITH SUCH ACTIVITIES, AS
                    YOU COULD DO IN PERSON. YOU ACKNOWLEDGE AND AGREE THAT WHEN COPILOT IS
                    ACCESSING AND RETRIEVING ACCOUNT DATA FROM THIRD-PARTY DATA SOURCES, COPILOT IS
                    ACTING AS YOUR AGENT, AND NOT AS THE AGENT OF OR ON BEHALF OF ANY THIRD PARTY.
                    YOU AGREE THAT THIRD-PARTY DATA SOURCES WILL BE ENTITLED TO RELY ON THE
                    FOREGOING AUTHORIZATION, AGENCY, AND POWER OF ATTORNEY GRANTED BY YOU. You
                    understand that the Service is not endorsed or sponsored by any Third-Party
                    Data Sources accessible through the Service.</p>

                    <p>E.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Deactivation</i></b>.
                    Copilot may, at any time and without notice to you: (1) restrict, deactivate,
                    and/or terminate your access to the Service (or any portion thereof); or (2) terminate
                    or modify the Service (or any portion thereof). Copilot will not be liable to
                    you or any third party for any termination of or modification to the Service
                    regardless of the reason for such termination or modification. If you are
                    dissatisfied with any termination or modification of the Service, your only
                    right is to terminate your use of the Service.</p>

                    <p>F.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Privacy
                    Policy</i></b>. Copilot’s policy with respect to the collection and use of your
                    personally identifiable information is described in our <Link to="/privacy-policy">Privacy Policy</Link>. By
                    accepting these Terms, you acknowledge your agreement with Copilot’s Privacy
                    Policy. </p>

                    <p>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Registration; Eligibility; Restrictions.</b></p>

                    <p><span>A.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span><b><i><span >Copilot
                    Users.</span></i></b><span >
                    To become a Copilot User, you must complete the registration process by
                    providing us with current, complete, and accurate information, as prompted by
                    the applicable registration form. </span></p>

                    <p><span>B.<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </span></span><b><i><span>Accuracy
                    of Information.</span></i></b><span>
                    You acknowledge that if you provide any information to us that is untrue,
                    inaccurate, not current, or incomplete, Copilot may terminate these Terms and
                    your continued access and use of the Service. </span></p>

                    <p>C.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Eligibility. </i></b>You represent and
                    warrant that you are at least 18 years of age and that you have not been
                    previously suspended or removed from the Service. THE SERVICE IS NOT FOR
                    PERSONS UNDER THE AGE OF 18 OR ANY USERS PREVIOUSLY SUSPENDED OR REMOVED FROM
                    THE SERVICE BY COPILOT. IF YOU ARE UNDER 18 YEARS OF AGE, PLEASE DO NOT USE OR ACCESS
                    THE SERVICE AT ANY TIME OR IN ANY MANNER.</p>

                    <p>D.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Credentials</i></b>.
                    As part of the registration process, you will may asked to select a
                    username and/or password or other login credentials. You are entirely
                    responsible for maintaining the security and confidentiality of your login
                    credentials. You agree to notify Copilot immediately of any unauthorized use of
                    your account or any other breach of security. To notify us, contact us at
                    support@copilot.money. You are responsible for all use of the Service occurring
                    under your account and all content posted with your account on the Service. You
                    are responsible for keeping your login credentials confidential and for
                    notifying us if your login credentials have been hacked or stolen. Copilot will
                    not be liable for any loss that you may incur as a result of someone else using
                    your login credentials or account, either with or without your knowledge. You
                    may be held liable for any losses incurred by Copilot or another party due to
                    someone else using your account or login credentials.</p>

                    <p>E.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Your Responsibilities</i></b>. You may
                    use the Service solely for lawful purposes, as intended through the provided
                    functionality of the Service. You may not use the Service in any manner that
                    could damage, disable, overburden, or impair our servers or networks, or
                    interfere with any other party’s use and enjoyment of the Service. You may not
                    attempt to gain unauthorized access to the Service, user accounts, or computer
                    systems or networks, through hacking, password mining, or any other means.
                    Without limiting any of the foregoing, you may not (and you may not allow or
                    assist any third party to):</p>

                    <p>(1)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; use, copy, install, transfer, or distribute
                    the Service, except as specifically described in these Terms;</p>

                    <p>(2)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; modify, adapt, translate, reverse engineer,
                    decompile, or disassemble any portion of the Service;</p>

                    <p>(3)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; remove or alter any copyright, trademark, or
                    other proprietary rights notices contained in or on the Service or in or on any
                    content or other material obtained through the Service or the use of the
                    Service;</p>

                    <p>(4)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; probe, scan, or test the vulnerability of any
                    system or network or breach any security or authentication measures;</p>

                    <p>(5)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; reformat, mirror, or frame any portion of the
                    web pages that are part of the Service;</p>

                    <p>(6)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; express or imply that any statements you make
                    are endorsed by us, without our prior written consent in each instance;</p>

                    <p>(7)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; transmit any software or other materials that
                    contain any virus, worm, time bomb, Trojan horse, or other harmful or
                    disruptive component;</p>

                    <p>(8)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; use any robot, spider, site search/retrieval
                    application, or other manual or automatic device or process to retrieve, index,
                    “data mine,” or in any way reproduce or circumvent the navigational structure
                    or presentation of the Service or its contents;</p>

                    <p>(9)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; harvest or collect information about other Copilot
                    Users without their prior written consent;</p>

                    <p>(10)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; undertake, cause, permit, or authorize the
                    translation, reverse engineering, disassembling, or hacking of any aspect of
                    the Service, or attempt to do any of the foregoing, except and solely to the
                    extent described in these Terms, the Service’s authorized features, or by law,
                    or otherwise attempt to use or access any portion of the Service other than as
                    intended by Copilot;</p>

                    <p>(11)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; access, tamper with, or use non-public areas of
                    the Service, Copilot’s (and its service providers’) computer systems and
                    infrastructure, or the technical delivery systems of Copilot’s service providers;</p>

                    <p>(12)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; harass, abuse, harm, or advocate or incite
                    harassment, abuse, or harm of another person or group, including Copilot
                    employees, and other Copilot Users;</p>

                    <p>(13)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; solicit, or attempt to solicit, personal
                    information from other Copilot Users, except as permitted through the Service’s
                    functionality;</p>

                    <p>(14)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; restrict, discourage, or inhibit any person
                    from using the Service, disclose personal information about a third person on
                    the Service or obtained from the Service without the consent of such person, or
                    collect information about Copilot Users;</p>

                    <p>(15)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; gain unauthorized access to the Service, to
                    other Copilot Users’ accounts, names, or personally identifiable information,
                    or to other computers or websites connected or linked to the Service;</p>

                    <p>(16)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; violate any applicable federal, state, or local
                    laws, regulations, or these Terms;</p>

                    <p>(17)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; use the Service for any illegal, inappropriate,
                    and/or unauthorized conduct, including using the Service to contact other Copilot
                    Users for sexual or other inappropriate purposes, or using the Service in
                    violation of Copilot’s or any third party's intellectual property or other
                    proprietary or legal rights; or</p>

                    <p>(18)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; use or access the Service to build a competing
                    service.</p>

                    <p>We may take any legal action and implement any technical
                    remedies to prevent the violation of these provisions and to enforce these Terms.
                    </p>

                    <p>3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Consent to
                    Electronic Communications.</b></p>

                    <p>A.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Consent to
                    Electronic Communications</i></b>. By using the Service or providing personal information
                    to us, you agree that we may communicate with you electronically regarding
                    security, privacy, and administrative issues relating to your use of the
                    Service. If we learn of a security system’s breach, we may attempt to notify
                    you electronically by posting a notice on the Service or sending an email to
                    you, if we have your email address. You may have a legal right to receive this
                    notice in writing. To receive free written notice of a security breach (or to
                    withdraw your consent from receiving electronic notice), please write to us at
                    support@copilot.money. </p>

                    <p>B.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Push
                    Notifications</i></b>. When you register with Copilot, Copilot will send you
                    automatic and voluntary push notifications based on the notification
                    preferences you have selected (“<b>Notifications</b>”). Some Notifications may
                    be turned on by default. By using the Service, you agree to receive Notifications
                    regarding your use of the Service and your Account Data. While Notifications
                    are intended to enhance your use of the Service, you may disable Notifications on
                    your device. Depending on which Notifications you elect to receive,
                    Notifications may contain sensitive information.</p>

                    <p>C.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Opt Out</i></b>. If you wish to remove
                    yourself from any list (except as described in Section 3.B (Push
                    Notifications)), please email us at unsubscribe@copilot.money with
                    "OPT-OUT," "UNSUBSCRIBE," "STOP," or
                    "REMOVE" in the subject line, or click the “unsubscribe” link in an
                    email you have received from us. </p>

                    <p>D.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Message Delivery</i></b>.
                    Copilot cannot control certain factors relating to message delivery. We may not
                    be able to transmit a Notification to you in a timely and accurate manner. We
                    have no liability for transmission delays or Notification failures, for any
                    erroneous content in a Notification, or for any actions taken or not taken in
                    reliance on a Notification.</p>

                    <p>4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Content Submitted to </b><b>the Service.</b></p>

                    <p>A.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Materials</i></b>. By sending or
                    transmitting to us information, opinions, creative suggestions, ideas, notes,
                    concepts, or other materials (collectively, “<b>Materials</b>”), or by posting
                    such Materials to any area of the Service, you grant Copilot and its designees
                    a worldwide, non-exclusive, sublicenseable (through multiple tiers),
                    assignable, royalty-free, perpetual, irrevocable license to use, reproduce,
                    distribute (through multiple tiers), create derivative works of, publicly
                    perform, publicly display, digitally perform, make, have made, sell, offer for
                    sale, and import such Materials in any media now known or hereafter developed,
                    for the purpose of providing, enhancing, and developing the Service, without
                    compensation to you. We will never use your name in connection with any of your
                    Materials that we use in our own advertising and marketing materials without
                    obtaining your prior consent. You hereby waive any moral rights or other rights
                    with respect to attribution of authorship regarding Materials that you may have
                    under applicable law. None of the Materials will be subject to any obligation,
                    whether of confidentiality, attribution, or otherwise, on our part and we will
                    not be liable for any use or disclosure of any Materials. Copilot may remove or
                    alter any Materials at any time for any reason. We neither endorse nor are
                    responsible for any opinion, advice, information, or statement made or
                    displayed on the Service by any Copilot User. We are not responsible for any
                    errors or omissions in articles or postings, for hyperlinks embedded in
                    messages, or for any results obtained from the use of such information. Under
                    no circumstances will Copilot and/or its affiliates, suppliers, or agents be
                    liable for any loss or damage caused by your reliance on such information
                    obtained through the Service. We cannot and do not take responsibility for the
                    veracity, reliability, or completeness of any opinion, advice, information, or
                    statement available on the Service.</p>

                    <p>B.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Monitoring</i></b>. We have the right
                    (but not the obligation) to: (1) monitor the Service and Materials; (2) alter
                    or remove any Materials; and (3) disclose Materials and the circumstances
                    surrounding their transmission to any third party in order to operate the Service,
                    protect ourselves, our sponsors, and our members and visitors, and to comply
                    with legal obligations or governmental requests. If you believe any content
                    violates our member policies, please contact Copilot immediately at support@copilot.money
                    so that we can consider its editing or removal.</p>

                    <p>C.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Rights in
                    Materials</i></b>. You are solely responsible for your Materials and the
                    consequences of posting them on the Service. By posting Materials, you
                    represent, warrant, and covenant that: (1) you are the creator and owner of the
                    Materials or otherwise have sufficient rights and authority to grant the rights
                    granted herein; (2) your Materials do not and will not: (a) infringe, violate,
                    or misappropriate any third-party right, including any copyright, trademark,
                    patent, trade secret, moral right, privacy right, right of publicity, or any
                    other intellectual property or proprietary right; or (b) slander, defame, or
                    libel any other person; (3) your Materials do not contain any viruses, adware,
                    spyware, worms, or other harmful or malicious code; and (4) unless you have
                    received prior written authorization, your Materials do not contain any
                    confidential information of any third party. We reserve all rights and remedies
                    against any Copilot Users who breach these representations and warranties.</p>

                    <p>5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Payment
                    Terms and Order Processing.</b></p>

                    <p><span>A.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Access to the Service is free
                    of charge. In the future, or to access certain new features of the Service, we
                    may require you to pay fees, and may provide you the option to activate
                    recurring automatic payments for those fees. Before you pay any fees, including
                    before activating or updating any recurring payments, you will have an
                    opportunity to review the fees that you will be charged before you accept them.
                    If you activate or update recurring payments through the Service, you authorize
                    Copilot to periodically charge, on a going-forward basis and until
                    cancellation, all accrued sums on or before the payment due date for the
                    accrued sums. <b>You may cancel recurring charges at any time via the
                    functionality of the Service or by emailing Copilot directly at support@copilot.money.
                    It may take up to three business days for the update or cancellation to take
                    effect.</b></span></p>

                    <p><span>B.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; All fees are in U.S. Dollars
                    and are non-refundable. Copilot may change the fees for the Service or any
                    feature of the Service, including by adding fees, on a going-forward basis at
                    any time. If Copilot changes the fees for the Service, including by adding
                    additional fees or charges, you will be provided advance notice of those
                    changes and the opportunity to accept the changes. If you do not accept the
                    changes, Copilot may discontinue providing the Service to you.</span></p>

                    <p>C.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Copilot will charge the payment method you
                    specify at the time of purchase. You authorize Copilot to charge all sums as
                    described in these Terms, for the Service you select, to that payment method.
                    If you pay any fees with a credit card, Copilot may seek pre-authorization of
                    your credit card account prior to your purchase to verify that the credit card
                    is valid and has the necessary funds or credit available to cover your purchase.
                    </p>

                    <p>6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Term and
                    Termination.</b></p>

                    <p>A.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Term</i></b>. The term of these Terms will
                    commence on the date on which you first access or utilize the Service in any
                    way and will continue so long as you continue to access or utilize the Service,
                    unless earlier terminated by Copilot. </p>

                    <p>B.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Termination and Other Remedies</i></b>. Copilot
                    may take whatever lawful actions it may deem appropriate in response to actual
                    or suspected violations of these Terms including termination of these Terms, the
                    suspension or termination of your access and/or account, or blocking you from
                    access to the Service. </p>

                    <p>C.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Effect of
                    Termination</i></b>. The following Sections of these Terms will survive
                    termination or expiration of these Terms: 1.A, 1.B, 1.D, 1.E, 1.F, 2, 4, 5, 6.C,
                    and 7–27. Payments by you, which accrue or are due before termination or
                    expiration of these Terms, will continue to be payable by you, and amounts owed
                    to Copilot at the time of such termination or expiration, will continue to be
                    owed by you after such expiration or termination.</p>

                    <p>7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Ownership</b><b>.</b></p>

                    <p>A.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Proprietary Information</i></b>. You
                    acknowledge and agree that: (1) the Service, including any content, modifications,
                    enhancements and updates, and any originals and copies thereof, in whole or in
                    part, and all intellectual property rights therein (collectively, “<b>Proprietary
                    Information</b>”), is owned by Copilot and its licensors, as applicable; (2)
                    the Proprietary Information contains valuable copyrighted and proprietary
                    material of Copilot; (3) the Proprietary Information is licensed, rather than
                    sold, to you pursuant to these Terms; and (4) you have no rights in the
                    Proprietary Information, other than the rights and licenses specifically
                    granted to you pursuant to these Terms.</p>

                    <p>B.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Trademarks</i></b>. You acknowledge that
                    Copilot has acquired, and is the owner of, common law or registered trademark
                    rights in the name and word mark "Copilot" and in the other marks and
                    design marks displayed on the Service. You acknowledge that this name and these
                    marks are famous and internationally known. You will not, at any time or for
                    any reason, challenge the validity of, or Copilot’s ownership of, the foregoing
                    name and marks, and you waive any rights you may have at any time to do so. You
                    may not use our name or marks in connection with any product or service that is
                    not ours, or in any manner that is likely to cause confusion. All use of the
                    foregoing name and marks by you will inure exclusively to the benefit of Copilot.
                    All marks shown on the Service but not owned by Copilot are the property of
                    their respective owners.</p>

                    <p>8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Disclaimer
                    of Warranty</b><b>.</b></p>

                    <p>A.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>DISCLAIMER</i></b>. TO THE FULLEST
                    EXTENT PERMITTED BY LAW, THE SERVICE IS PROVIDED “AS IS” WITH ALL FAULTS, AND
                    THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY, AND EFFORT
                    IS WITH YOU. YOU ACKNOWLEDGE THAT, BECAUSE OF THE NATURE OF THE INTERNET,
                    MOBILE NETWORKS, AND THE DEVICES WHICH ACCESS THE INTERNET AND/OR MOBILE
                    NETWORKS, THE SERVICE MAY NOT BE ACCESSIBLE WHEN NEEDED, AND THAT INFORMATION,
                    DATA, AUDIO, AND VIDEO TRANSMITTED OVER THE INTERNET AND/OR MOBILE NETWORKS MAY
                    BE SUBJECT TO INTERRUPTION OR THIRD-PARTY INTERCEPTION AND MODIFICATION. TO THE
                    FULLEST EXTENT PERMITTED BY LAW, COPILOT DISCLAIMS ALL WARRANTIES OR
                    CONDITIONS, EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OR CONDITIONS
                    OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, QUIET
                    ENJOYMENT, ACCURACY OF INFORMATIONAL CONTENT, AND ABSENCE OF VIRUSES AND
                    DAMAGING OR DISABLING CODE. TO THE FULLEST EXTENT PERMITTED BY LAW, NONE OF COPILOT,
                    ITS AFFILIATES OR SERVICE PROVIDERS, COPILOT’S CONTENT PROVIDERS, AND/OR THEIR
                    RESPECTIVE AFFILIATES, SUBSIDIARIES, EMPLOYEES, AGENTS, AND/OR CONTRACTORS
                    (COLLECTIVELY, THE “<b>COPILOT PARTIES</b>”) WARRANT THE ACCURACY, COMPLETENESS,
                    OR TIMELINESS OF THE SERVICE. COPILOT CANNOT AND DOES NOT ASSUME ANY
                    RESPONSIBILITY FOR ANY LOSS, DAMAGES, OR LIABILITIES ARISING FROM THE FAILURE
                    OF ANY TELECOMMUNICATIONS INFRASTRUCTURE, THE INTERNET, OR FOR YOUR MISUSE OF
                    ANY CONTENT AND INFORMATION ACCESSED THROUGH THE SERVICE. YOUR USE OF THE SERVICE
                    AND YOUR RELIANCE UPON ANY OF THE RESPECTIVE CONTENT IS AT YOUR SOLE RISK.
                    NEITHER COPILOT NOR ITS LICENSORS WILL BE CONSIDERED AN "EXPERT"
                    UNDER THE SECURITIES ACT OF 1933. NEITHER COPILOT NOR ITS LICENSORS WARRANT
                    THAT THE SERVICE COMPLIES WITH THE REQUIREMENTS OF THE FINANCIAL INDUSTRY
                    REGULATORY AUTHORITY, THE SECURITIES AND EXCHANGE COMMISSION, OR ANY SIMILAR
                    ORGANIZATION OR REGULATOR OR WITH THE SECURITIES LAWS OF ANY JURISDICTION.</p>

                    <p>B.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Third-Party Information</i></b>. You
                    acknowledge that any information you obtain from another Copilot User comes
                    from those individuals, and not from Copilot, and that Copilot, to the fullest
                    extent permitted by law, is not in any way responsible for any of the
                    information these third parties may supply or for any statements, claims, or
                    representations they may make. To the fullest extent permitted by law, Copilot
                    disclaims any such statements, claims, or representations and the same do not
                    expand or otherwise modify these Terms. If you are dissatisfied with the
                    Service, your sole and exclusive remedy is to stop accessing and using the
                    Service. </p>

                    <p>C.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Account Data</i></b>. COPILOT IS NOT
                    RESPONSIBLE FOR THE ACCURACY OF THE ACCOUNT DATA OBTAINED FROM THIRD-PARTY DATA
                    SOURCES THAT ARE DISPLAYED OR REPORTED THROUGH THE SERVICE. COPILOT DOES NOT
                    REPRESENT OR WARRANT THAT IT WILL BE ABLE TO RETRIEVE YOUR ACCOUNT DATA FROM
                    ANY THIRD-PARTY DATA SOURCE. If you believe any Account Data is inaccurate or
                    outdated, you should contact the applicable Third-Party Data Source. By using
                    the Service, you acknowledge that the investment results you could obtain from
                    financial insights provided by Copilot cannot be guaranteed. All investments
                    entail a risk of loss and you may lose money. You also understand that all
                    investments are subject to various economic, political, and other risks. While
                    the Service may assist you in helping you manage your finances, you should consult
                    with a professional financial advisor before making investment decisions or
                    deciding on significant changes to your personal financial strategy. Any
                    information provided by or through the Service does not constitute financial,
                    investment, legal, accounting, tax, or other advice.</p>

                    <p>9.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Limitation
                    of Liability</b><b>.</b></p>

                    <p>A.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>LIMITATION</i></b>. TO THE FULLEST
                    EXTENT PERMITTED BY LAW: (1) EACH COPILOT USER IS SOLELY RESPONSIBLE FOR: (A)
                    HIS OR HER USE OF THE SERVICE; AND (B) ANY DAMAGES INCURRED BY HIM OR HER OR
                    ANY THIRD PARTY THAT ARISE FROM OR ARE RELATED TO THE SERVICE; (2) THE
                    AGGREGATE LIABILITY OF THE COPILOT PARTIES FOR ANY DAMAGES, WHETHER ARISING IN
                    CONTRACT, TORT, OR OTHERWISE, WILL BE LIMITED TO ACTUAL DAMAGES PROVED, AND NOT
                    TO EXCEED THE GREATER OF THE AMOUNT OF FEES PAID BY YOU UNDER THIS AGREEMENT OR
                    $100; AND (3) NONE OF THE COPILOT PARTIES WILL BE LIABLE FOR ANY PUNITIVE,
                    SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES, EVEN IF SUCH COPILOT PARTIES HAVE
                    BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</p>

                    <p>B.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>INDEPENDENT INVESTIGATION</i></b>. YOU
                    REPRESENT THAT YOU HAVE INDEPENDENTLY INVESTIGATED THE ADVISABILITY OF USING
                    THE SERVICE AND THE POSSIBLE RISKS INVOLVED IN USING THE SERVICE. YOU AGREE TO
                    MAINTAIN YOUR OWN INSURANCE COVERING SUCH RISKS AND WILL LOOK SOLELY TO SUCH
                    INSURANCE FOR REIMBURSEMENT OF ANY RESULTING DAMAGES.</p>

                    <p>10.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Third-Party Disputes</b>.
                    COPILOT IS NOT AFFILIATED WITH ANY OTHER COPILOT USER, CARRIER, SERVICE
                    PROVIDER, OR THIRD-PARTY SERVICE, AND ANY DISPUTE YOU HAVE WITH ANY OTHER COPILOT
                    USER, CARRIER, SERVICE PROVIDER, THIRD-PARTY SERVICE, OR OTHER THIRD PARTY
                    ARISING FROM YOUR USE OF THE SERVICE, INCLUDING YOUR EMPLOYER, IS DIRECTLY
                    BETWEEN YOU AND SUCH THIRD PARTY, AND YOU IRREVOCABLY RELEASE COPILOT (AND OUR
                    OFFICERS, DIRECTORS, AGENTS, SUBSIDIARIES, JOINT VENTURERS, AND EMPLOYEES) FROM ALL CLAIMS, DEMANDS, AND
                    DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN,
                    ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES. </p>

                    <p>11.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Force Majeure</b>. &nbsp;Any
                    computer system, service, or electronic device, whether it is yours, an
                    internet service provider’s, a mobile network operator’s, or ours, can
                    experience unanticipated outages, slowdowns, or capacity limitations. As a
                    result of high internet traffic volume, transmission problems, systems capacity
                    limitations, and other problems, you may experience difficulty accessing the
                    Service or communicating with us through the internet or other electronic and
                    wireless services. The Service may be unavailable during system maintenance,
                    for security precautions, or when interrupted by circumstances beyond our
                    control. Copilot will not be liable for any delay or failure to perform any
                    obligation herein if the delay or failure is due to any of the foregoing or for
                    any other unforeseen events that are beyond Copilot’s reasonable control, such
                    as strikes, blockade, war, terrorism, riots, natural disasters, epidemic, or
                    governmental action.</p>

                    <p>12.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Indemnification</b><b>.</b><b> </b></p>

                    <p>A.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Indemnity</i></b><i>. </i>To the fullest
                    extent permitted by law, you will defend, indemnify, and hold the Copilot
                    Parties harmless against any loss or damage of any kind (including attorneys’
                    fees and lost revenues) arising from: (1) any breaches by you of these Terms or
                    any representation, warranty, or covenant contained in these Terms; (2) any use
                    of the Service not specifically authorized in these Terms or on the Service;
                    and (3) any claims and actions against any Copilot Party by other parties to
                    whom you allow access to the Service.</p>

                    <p>B.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Procedure</i></b>. We reserve the right,
                    at our own expense, to assume the exclusive defense and control of any matter
                    otherwise subject to indemnification by you, and in such case, you agree to
                    cooperate with our defense of such claim. You will not, in any event, settle
                    any such claim or matter without our written consent.</p>

                    <p>13.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Additional
                    Service Features. </b></p>

                    <p>A.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Access to Third-Party Services</i></b>. The
                    Service may contain information on products and services provided by third
                    parties and links (including advertisements) to third-party websites
                    (collectively, “<b>Third-Party Services</b>”). Third-Party Services are
                    provided only as a convenience to Copilot Users. Copilot does not review or
                    control Third-Party Services, and Copilot does not make any representations or
                    warranties, express or implied, regarding Third-Party Services. Inclusion of
                    any Third-Party Services in the Service does not constitute or imply an
                    endorsement, authorization, sponsorship, or affiliation by or with Copilot with
                    respect to any Third-Party Services. Copilot is under no obligation to maintain
                    any link on the Service and may remove a link at any time in its sole
                    discretion for any reason whatsoever.</p>

                    <p>B.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Disclaimer of Liability for Third-Party
                    Services</i></b>. Third-Party Services may have their own terms of use and
                    privacy policies, and your use of the Service may incur third-party fees, such
                    as fees charged by your carrier for data usage. You are solely responsible for
                    reviewing and complying with any terms of use, privacy policies, or other terms
                    governing your use of Third-Party Services, and you are solely responsible for
                    all of Third-Party Services fees incurred by you for use of the Service. You
                    are solely responsible for taking the precautions necessary to protect yourself
                    from fraud when using Third-Party Services, and to protect your computer systems
                    from viruses, worms, Trojan horses, and other harmful or destructive content
                    that may be included on or with any Third-Party Services. Copilot disclaims any
                    responsibility or liability for any harm resulting from your use of Third-Party
                    Services, and you hereby irrevocably waive any claim against Copilot with
                    respect to any Third-Party Services.</p>

                    <p>14.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Dispute
                    Resolution.</b></p>

                    <p>A.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Generally</i></b>.
                    In the interest of resolving disputes between you and Copilot in the most
                    expedient and cost-effective manner, you and Copilot agree that any dispute arising out of or in any way related to these
                    Terms or your use of the Service will be resolved
                    by binding arbitration. Arbitration uses a neutral arbitrator instead of a
                    judge or jury and can be subject to very limited review by courts. Arbitrators
                    can award the same damages and relief that a court can award. This agreement to
                    arbitrate disputes includes all claims arising out
                    of or in any way related to these Terms or your use of the Service, whether
                    based in contract, tort, statute, fraud, misrepresentation, or any other legal
                    theory, and regardless of whether a claim arises during or after the expiration or termination
                    of these Terms. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THESE TERMS,
                    YOU AND COPILOT ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE
                    IN A CLASS OR COLLECTIVE ACTION. YOU FURTHER UNDERSTAND THAT THESE DISPUTE RESOLUTION
                    TERMS WILL APPLY TO YOU AND COPILOT UNLESS YOU CHOOSE TO OPT OUT PURSUANT TO SECTION
                    14.I (OPT OUT).</p>

                    <p>Whether to agree to arbitration is an important business
                    decision. It is your decision to make, and you should not rely solely on the
                    information provided in these Terms as it is not intended to contain a complete
                    explanation of the consequences of arbitration. You should take further steps
                    to conduct research and to consult with others, including an attorney,
                    regarding the consequences of your decision, just as you would when making any
                    other important business or life decision.</p>

                    <p>B.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Exceptions</i></b>. Despite
                    the provisions of Section 14.A (Generally), nothing in these Terms will be
                    deemed to waive, preclude, or otherwise limit the right of either party to: (1)
                    bring an individual action in small claims court; (2) bring an action in a
                    court pursuant to the California Private Attorneys General Act of 2004,
                    California Labor Code § 2698 et seq.; (3) seek injunctive relief in a court of law; or (4)
                    file suit in a court
                    of law to address an intellectual property infringement claim.</p>

                    <p>C.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Arbitrator</i></b>. Any
                    arbitration between you and Copilot will be governed by the Federal Arbitration
                    Act, and governed by the Commercial Dispute Resolution Procedures and the
                    Supplementary Procedures for Consumer Related Disputes (collectively, “<b>AAA
                    Rules</b>”) of the American Arbitration Association (“<b>AAA</b>”), as modified
                    by these Terms, and will be administered by the AAA. The AAA Rules and filing
                    forms are available online at www.adr.org, by calling the AAA at
                    1-800-778-7879, or by contacting Copilot. The arbitrator has exclusive authority to resolve any
                    dispute relating to the interpretation, applicability, or enforceability of
                    this binding arbitration agreement.</p>

                    <p>D.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Notice; Process</i></b>. A
                    party who intends to seek arbitration must first send a written notice of the
                    dispute to the other party by U.S. Mail within the applicable statute of
                    limitations period (“<b>Notice</b>”). Copilot’s address for Notice is: 5 Tudor City Place, Apt 1118, New York, NY 10017. The
                    Notice must: (1) describe the nature and basis of the claim or dispute; and (2)
                    set forth the specific relief sought (“<b>Demand</b>”). The parties will make
                    good faith efforts to resolve the claim directly, but if the parties do not
                    reach an agreement to do so within 30 days after the Notice is received, you or
                    Copilot may commence an arbitration proceeding. During the arbitration, the
                    amount of any settlement offer made by you or Copilot must not be disclosed to
                    the arbitrator until after the arbitrator makes a final decision and award, if
                    any. If the dispute is finally resolved through arbitration in your favor, Copilot
                    will pay you the highest of the following: (a) the amount awarded by the
                    arbitrator, if any; (b) the last written settlement amount offered by Copilot
                    in settlement of the dispute prior to the arbitrator’s award; or (c) $1,000.</p>

                    <p>E.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Fees</i></b>. If you
                    commence arbitration in accordance with these Terms, Copilot will reimburse you
                    for your payment of the filing fee, unless your claim is for more than $15,000
                    or as set forth below, in which case the payment of any fees will be decided by
                    the AAA Rules. Any arbitration hearing will take place at a location to be
                    agreed upon in Wilmington, Delaware, but if the claim is for $15,000 or less,
                    you may choose whether the arbitration will be conducted: (1) solely on the basis
                    of documents submitted to the arbitrator; (2) through a non-appearance based
                    telephone hearing; or (3) by an in-person hearing as established by the AAA
                    Rules in the county (or parish) of your billing address. If the arbitrator
                    finds that either the substance of your claim or the relief sought in the
                    Demand is frivolous or brought for an improper purpose (as measured by the
                    standards set forth in Federal Rule of Civil Procedure 11(b)), then the payment
                    of all fees will be governed by the AAA Rules. In that case, you agree to
                    reimburse Copilot for all monies previously disbursed by it that are otherwise
                    your obligation to pay under the AAA Rules. Regardless of the manner in which
                    the arbitration is conducted, the arbitrator must issue a reasoned written decision
                    sufficient to explain the essential findings and conclusions on which the
                    decision and award, if any, are based. The arbitrator may make rulings and
                    resolve disputes as to the payment and reimbursement of fees or expenses at any
                    time during the proceeding and upon request from either party made within 14
                    days of the arbitrator’s ruling on the merits.</p>

                    <p>F.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>No
                    Class Actions</i></b>. YOU AND COPILOT AGREE THAT EACH MAY BRING CLAIMS AGAINST
                    THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR
                    CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING (OTHER THAN
                    ACTIONS UNDER THE CALIFORNIA PRIVATE ATTORNEYS GENERAL ACT OF 2004, CALIFORNIA
                    LABOR CODE § 2698 ET SEQ. WHICH ARE NOT COVERED BY THIS SECTION 14 (DISPUTE
                    RESOLUTION)). Unless both you and Copilot agree otherwise, the arbitrator may
                    not consolidate more than one person’s claims, and may not otherwise preside
                    over any form of a representative or class proceeding.</p>

                    <p>G.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Modifications to this
                    Arbitration Provision</i></b>. If Copilot makes any future change to this
                    arbitration provision, other than a change to Copilot’s address for Notice, you
                    may reject the change by sending us written notice within 30 days of the change
                    to Copilot’s address for Notice, in which case this arbitration provision, as
                    in effect immediately prior to the changes you rejected, will continue to
                    govern any disputes between you and Copilot. If you do not send such written notice, your continued
                    use of the Service following any such change means that you have consented to
                    such change.</p>

                    <p>H.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Enforceability</i></b>. If
                    Section 14.F (No Class Actions) is found to be unenforceable or if the entirety
                    of this Section 14 (Dispute Resolution) is found to be unenforceable, then the
                    entirety of this Section 14 (Dispute Resolution) will be null and void and the
                    exclusive jurisdiction and venue described in Section 17 (Governing Law; Choice
                    of Forum) will govern any action arising out of or related to these Terms or
                    your use of the Service.</p>

                    <p>I.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>Opt Out</i></b>.
                    Arbitration is not a mandatory condition of these Terms. If you do not want to
                    be subject to this Section 14 (Dispute Resolution), you may opt out of this Section
                    14 (Dispute Resolution) by notifying Copilot in writing of your decision by sending,
                    within 30 days of the date you receive these Terms, an electronic message to support@copilot.money,
                    stating clearly your full name and intent to opt out of this Section 14 (Dispute
                    Resolution). Should you choose not to opt out of this Section 14 (Dispute
                    Resolution) within the 30-day period, you and Copilot will be bound by the
                    terms of this Section 14 (Dispute Resolution). You have the right to consult
                    with counsel of your choice concerning this Section 14 (Dispute Resolution).
                    You understand that you will not be subject to retaliation if you exercise your
                    right to opt out of coverage under this Section 14 (Dispute Resolution).</p>

                    <p>15.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Cooperation with Authorities</b>. Copilot
                    may cooperate with legal authorities and/or third parties in the investigation
                    of any suspected or alleged crime or civil wrong. Except as may be expressly
                    limited by the Privacy Policy, Copilot may disclose any information as Copilot
                    deems necessary to satisfy any applicable law, regulation, legal process, or
                    governmental request, or to edit, refuse to post, or to remove any information
                    or materials, in whole or in part, in Copilot’s sole discretion.</p>

                    <p>16.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Protected Activity Not Prohibited</b>. To
                    the extent permitted by applicable law, nothing in these Terms will in any way
                    limit or prohibit you from filing a charge or complaint with, or otherwise
                    communicating or cooperating with or participating in, any investigation or
                    proceeding (“<b>Protected Activity</b>”) that may be conducted by any federal,
                    state, or local government agency or commission (“<b>Government Agencies</b>”).
                    In connection with such Protected Activity, you are permitted to disclose
                    documents or other information as permitted by law, and without giving notice
                    to, or receiving authorization from, Copilot. In making any such disclosures or
                    communications, you agree to take all reasonable precautions to prevent any
                    unauthorized use or disclosure of any information that may constitute Copilot
                    confidential information to any parties other than the Government Agencies. </p>

                    <p>17.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Governing Law; Choice
                    of Forum</b>. The laws of the State of New York, excluding its conflicts of law
                    rules, govern these Terms and your use of the Service. Your use of the Service
                    may also be subject to other local, state, national, or international laws;
                    provided, however, that the United Nations Convention on Contracts for the
                    International Sale of Goods will not apply to any provision of these Terms. To
                    the extent that any action relating to any dispute hereunder is permitted to be
                    brought in a court of law, such action will be subject to the exclusive
                    jurisdiction of the state and federal courts located in New York County, New
                    York and you hereby irrevocably submit to personal jurisdiction in such courts,
                    and waive any defense of inconvenient forum.</p>

                    <p>18.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Feedback</b>. If you provide any feedback to
                    Copilot concerning the functionality and performance of the Service (including
                    identifying potential errors and improvements), you hereby assign to Copilot
                    all right, title, and interest in and to such feedback, and Copilot is free to
                    use such feedback without payment or restriction.</p>

                    <p>19.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Entire Agreement; Variation</b>. These Terms
                    and the Privacy Policy set forth the entire agreement between Copilot and you
                    with respect to the Service. These Terms supersede and govern all previous oral
                    and written communications regarding these matters, all of which are merged
                    into these Terms. No usage of trade or other regular practice or method of
                    dealing between the parties will be used to modify, interpret, supplement, or
                    alter these Terms. </p>

                    <p>20.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Severability</b>. If any provision of these
                    Terms is held invalid, illegal, or unenforceable, such provisions will be modified,
                    or if not possible, severed, to reflect the fullest valid, legal, and
                    enforceable expression of the intent of the parties, and the remainder of these
                    Terms will not be affected thereby.</p>

                    <p>21.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Relationship of Parties</b>. Nothing in
                    these Terms will be deemed to create an employer-employee relationship between Copilot
                    and you, nor any agency, joint venture, or partnership relationship between the
                    parties. Neither party will have the right to bind the other to any obligation,
                    nor have the right to incur any liability on behalf of the other.</p>

                    <p>22.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Waiver</b>. No delay, omission, or failure
                    to exercise any right or remedy provided under these Terms will be deemed to be
                    a waiver thereof or an acquiescence to the event giving rise to such right or
                    remedy, or a waiver of or acquiescence to any other right, remedy, or event.</p>

                    <p>23.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Assignment</b>. Neither these Terms nor any
                    of the rights granted to you herein may be assigned or transferred by you,
                    whether voluntarily or by operation of law, without the express prior written
                    permission of Copilot and any attempt to do so will be null and void. Copilot
                    may assign or transfer these Terms at any time without your permission.</p>

                    <p>24.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Third-Party
                    Beneficiaries</b>. The provisions of these Terms relating to the rights of Copilot
                    service providers are intended for the benefit of such service providers, and
                    such service providers, as third-party beneficiaries, will be entitled to
                    enforce such provisions in accordance with these Terms, irrespective of the
                    fact that they are not parties to these Terms.</p>

                    <p>25.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Export Controls</b>.
                    You hereby represent and warrant that: (A) you are not located in a country
                    that is subject to a U.S. Government embargo, or that has been designated by
                    the U.S. Government as a “terrorist supporting” country; and (B) you are not
                    listed on any U.S. Government list of prohibited or restricted parties.</p>

                    <p>26.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Interpretation</b>. If Copilot provides a
                    translation of the English language version of these Terms, the translation is
                    provided solely for convenience, and the English version will prevail. Any
                    heading, caption, or section title contained in these Terms is for convenience
                    only, and in no way defines or explains any section or provision. Any use of
                    the term “including” or variations thereof in this Agreement shall be construed
                    as if followed by the phrase “without limitation.”</p>

                    <p>27.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>NOTICE FOR CALIFORNIA
                    USERS</b>. Under California Civil Code Section 1789.3, Copilot Users located in
                    California are entitled to the following notice: The Complaint Assistance Unit
                    of the Division of Consumer Services of the California Department of Consumer
                    Affairs may be contacted in writing at 400 R Street, Suite 1080, Sacramento,
                    California 95814, or by telephone at (916) 445-1254 or (800) 952-5210.</p>

                    <p><span>28.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span><b><span>NOTICE REGARDING APPLE.</span></b><span> You acknowledge that
                    these Terms are between you and Copilot only, not with Apple, and Apple is not
                    responsible for the Service or the content thereof. Apple has no obligation
                    whatsoever to furnish any maintenance and support services with respect to the
                    Service. In the event of any failure of the Service to conform to any
                    applicable warranty, then you may notify Apple and Apple will refund the
                    purchase price for the relevant Service to you; and, to the maximum extent
                    permitted by applicable law, Apple has no other warranty obligation whatsoever
                    with respect to the Service. Apple is not responsible for addressing any claims
                    by you or any third party relating to the Service or your possession and/or use
                    of the Service, including, but not limited to: (i) product liability claims;
                    (ii) any claim that the Service fails to conform to any applicable legal or
                    regulatory requirement; and (iii) claims arising under consumer protection or
                    similar legislation. Apple is not responsible for the investigation, defense,
                    settlement and discharge of any third-party claim that the Service or your
                    possession and use of the Service infringes that third party’s intellectual
                    property rights. You agree to comply with any applicable third-party terms when
                    using the Service. Apple, and Apple’s subsidiaries, are third-party
                    beneficiaries of these Terms, and upon your acceptance of these Terms, Apple
                    will have the right (and will be deemed to have accepted the right) to enforce these
                    Terms against you as a third-party beneficiary of these Terms. You hereby
                    represent and warrant that (i) you are not located in a country that is subject
                    to a U.S. Government embargo, or that has been designated by the U.S.
                    Government as a “terrorist supporting” country; and (ii) you are not listed on
                    any U.S. Government list of prohibited or restricted parties. If Copilot provides
                    a translation of the English language version of these Terms, the translation
                    is provided solely for convenience, and the English version will prevail. </span>
                    </p>       
                </SeriousTalk>
            </Grid>
        </Section>
        <Footer/>
    </Layout>
  )
}

export default TOSPage
